.footer-container {
    background-color: #242424;
    padding: 4rem 0 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.title {
    color: #fff;
    font-size: 2vh;
    text-align: center;
}
.social-media {
    text-align: center;
    color: #ffffff;
    padding-top: 20px;
}

.social-logo {
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: inline;
    align-self: center;
    margin-bottom: 10px;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: x-small;
}

.staff-resources {
    margin: 10px;
    text-align: center;
    color: #6C3092;;
    text-decoration: underline;
}
.container {
    background-image: url(../../public/images/background.jpg);
}

.contact-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
  }
  
  .messageTitle {
    text-align: center;
  }
  
  .holder {
    background-color: transparent;
    margin-top: 100px;
    padding: 20px;
    border-radius: 5px;
  }
  
  .holder-group {
    margin-bottom: 20px;
  }

  .name, .email, .msg {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  }
  
  label {
    display: block;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-submit {
    width: 100%;
    padding: 10px;
    background-color: #6C3092;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-submit:hover {
    background-color: #fff;
    color: #6C3092;
  }
  
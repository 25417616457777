
.icons {
    margin: 35px 25px;
    max-height: 120px;
}

.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    margin-bottom: -250px;
    font-family: 'Montserrat', sans-serif;
}

.main-all {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    margin-bottom: -300px;
    font-family: 'Montserrat', sans-serif;
    max-height: 800px;
    background-image: url(../images/background2.jpg);
    background-size: cover;
}

.exception{
    background-image: url(../images/background2.jpg);
    background-size: cover;
}

.responsive-type-animation1 {
    font-size: 12vw;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 15%;
}


/* Carousel */
a {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: #000;
    font-weight: 400;
}

.images {
    width: 27vmin;
    height: 27vmin;
    object-Fit: cover;
    min-width: 288px;
    min-height: 288px;
}

h3 {
    padding: 20px;
}

    .carousel .reel {
        white-space: nowrap;
        position: relative;
        -webkit-overflow-scrolling: touch;
        padding: 0 2em 0 2em;
    }

    .carousel article {
        width: 18em;
        margin: 0 1.25em 0 0;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #fff;
    }

.carousel {
    overflow-x: auto;
    padding: 2em 0 2em 0;
}

.main {
    background-image:url(../../public/images/background.jpg) ;
}

.background {
    font-size: 3vmax;
}

@media screen and (min-width: 900px) {
    .background {
        font-size: 28.86px;
    }
}

@media screen and (max-width: 1680px) {
    /* Carousel */

    .carousel {
        padding: 2em 0 2em 0;
    }

    .carousel .reel {
        padding: 0 1.5em 0 1.5em;
    }

    .carousel article {
        width: 18em;
        margin: 0 1.25em 0 0;
    }
    
}
@media screen and (max-width: 720px) {
    .all-icons {
        display: flex;
        flex-direction: column;
    }
}

.link-item {
    color: #6C3092;
    font-size: larger;
}

.link-item:hover {
    color: #8c8c8c;
}

.pics {
    padding-top: 30px;
}


.main-text {
    margin-top: 100px;
}

.p {
    display: inline;
    font-size: 28.86px;
}

.ap {
    font-size: 35px;
}

.name {
    margin-top: -100px;
}


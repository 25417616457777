.first-sentence {
    text-align: center;
    font-size: 4vmax;
}

.hover-popup-page {
    margin: 0 auto;
    padding: 20px;
    background-image: url(../../public/images/background.jpg);
    background-size: cover;
    font-family: 'Montserrat', sans-serif;
  }
  
  .tooltip-trigger {
    cursor: pointer;
    color: #6C3092;
    margin: 5px;
  }
  
  /* Tooltip styles */
  .tooltip {
    background-color: #6C3092;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    font-size: 1.5vmax;
  }

  .information {
    font-size: 3vmax;
    padding: 20px;
  }

  .requirements {
    list-style:square;
    margin: 20px;
  }

  .requirements-item {
    margin-bottom: 10px;
  }

  .seal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .seal {
    height: 200px;  
}

@media screen and (min-width: 900px) {
  .information, .first-sentence {
      font-size: 28.86px;
  }
}
.main-container {
    background-image: url(../images/background2.jpg);
    background-size: cover;
    min-height: 1119px;
}

.main-container > p {
    margin-top: 8px;
    color: #ffffff;
    font-size: 32px;
}

.main-btns {
    margin-top: 32px;
    margin-bottom: 32px;
}

.main-btns .btn {
    margin: 6px;
}


@media screen and (max-width: 870px) {
    .main-name {
        text-align: center;
    }
    
}
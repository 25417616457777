.navbar {
  font-size: 1.2rem;
}

.navbar-container {
  display: inherit;
  height: fit-content;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 1;
  max-height: 8vh;
}

.logo {
  max-height: 6vh;
  min-height: 6vh;
  margin: 1vmin;
  margin-left: 2vmin;
}

.menu-icon {
  display: none;
  color: #000;
}

.nav-menu {
  display: inline-flex;
  float: right;
  margin-right: 20px;
}

.nav-menu.active {
  border-bottom: 2px solid #fff;
}

.nav-item.active {
  border-bottom: 4px solid #6C3092;
  transition: all 0.2s ease-out;
}

.nav-item {
  height: 4vmax;
  display: inline;
}

.nav-links {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  max-height: 5vh;
  min-height: 5vh;
  margin-top: 3vh;
  padding-bottom: 2vh;
}

.nav-links:hover {
  border-bottom: 4px solid #6C3092;
  transition: all 0.2s ease-out;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #242424;
  border-radius: 5%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  text-decoration: none;
  color: white;
  padding: 5px;
  transition: background-color 0.3s;
  font-family: 'Montserrat', sans-serif;
}

.dropdown-item:hover {
  background-color: #777;
}

.sub-dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: -1px; /* Adjust this value to align the sub-dropdown menu with the parent item */
  margin-left: -10px;
  background-color: #242424;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2; /* Ensure the sub-dropdown is on top of the main dropdown */
}

.sub-dropdown-item {
  text-decoration: none;
  color: white;
  padding: 5px;
  transition: background-color 0.3s;
}

.sub-dropdown-item:hover {
  background-color: #777;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.fa-bars {
  color: #000;
}

@media screen and (max-width: 1025px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #635f5f;
  }

  .nav-menu.active {
    background: #242424;
    top: 7.8vh;
    height: auto;
    left: 0;
    z-index: 1;
  }

  .nav-item {
    margin-bottom: 25px;
  }
  .logo-name {
    font-size: 5vmax;
  }
  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding-top: 30px;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #6C3092;
    color: #fff;
    border-radius: 0;
  }
  
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 3vh;
    cursor: pointer;
    margin-top: 0vw;
  }

  .dropdown-menu {
    background-color: #fff;
    left: 0; /* Ensure it stays within the viewport */
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .dropdown-item {
    color: #000;
  }

  .sub-dropdown-menu {
    background-color: #fff;
    
  }

  .sub-dropdown-item {
    color: #000;
  }
} 

@media screen and (max-width: 720px) {
  .nav-menu.active {
    top: 8vh;
  }

  .menu-icon {
    font-size: 3vh;
  }
}

@media screen and (max-width: 650px) {
  .menu-icon {
    font-size: 3vh;
  }

  .dropdown-menu {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

/* Additional styles to push menu items down when dropdown is active */
.dropdown:hover .dropdown-menu {
  display: flex;
}

.nav-item.dropdown:hover + .nav-item {
  margin-top: 32px; /* Adjust this value based on the height of your dropdown */
}

/* App.css */
body { 
	font-family: Arial, sans-serif; 
	background-color: #f5f7fa; 
	margin: 0; 
	padding: 0; 
  }
  
  .app { 
	text-align: center; 
	margin: 20px; 
  }
  
  h1 { 
	font-size: 2.5rem; 
	text-align: center;
  }

  h2 {
	font-size: 1.5em;
  }

  .react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from {
	font-size: 20px;
	font-family: Montserrat, sans-serif;
  }
  
  .container { 
	display: flex; 
	justify-content: center; /* Center the container content */
	align-items: flex-start; /* Align to the top of the container */
	margin-top: 2rem; 
	width: 100%; /* Use full width of the page */
	transition: all 0.3s ease;
  }
  
  .calendar-container, .event-list {
	transition: width 0.3s ease;
	padding: 0;
	margin: 0;
  }
  
  .container-selected .calendar-container, .container-selected .event-list {
	width: 33%;
  }
  
  .container:not(.selected) .calendar-container, .container:not(.selected) .event-list {
	width: 50%;
  }
  
  .calendar-container { 
	flex: 1; 
	max-width: 500px; 
	margin-right: 1rem;
  }
  
  .event-container { 
	flex: 1; 
	max-width: 500px; 
  }
  
  @media screen and (max-width: 768px) { 
	.container { 
	  flex-direction: column; /* Stack elements vertically on small screens */
	}
  
	.calendar-container, 
	.event-container { 
	  max-width: 100%; 
	  margin-right: 0; 
	  margin-bottom: 2rem; 
	} 
  }
  
  .react-calendar { 
	width: 100%; 
	max-width: 500px; 
	background: rgba(255, 255, 255, 0.9); 
	border: 1px solid #ccc; 
	font-family: Arial, Helvetica, sans-serif; 
	line-height: 1.125em; 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
	border-radius: 8px; 
	padding: 10px; 
	transition: background-color 0.2s; 
  } 
  
  .react-calendar__month-view__weekdays { 
	font-size: 0.8rem; 
  } 
  
  .react-calendar__month-view__days__day--weekend { 
	color: #6C3092; 
  } 
  
  .react-calendar__tile:enabled:hover, 
  .react-calendar__tile:enabled:focus { 
	background-color: #c1c1c1; 
  } 
  
  .react-calendar__tile--now { 
	background: #ffff76; 
  } 
  
  .selected { 
	background-color: #ff4081 !important; 
	color: white; 
  } 
  
  .event-marked { 
	background-color: #6C3092; 
	color: white; 
  }
  
  /* Custom styles for month and year */
  .react-calendar__navigation__label {
	font-size: 20px; /* Adjust this value to your preferred font size */
  }
  
  .react-calendar__navigation button {
	font-size: 20px; /* Adjust this value to your preferred font size */
  }
  
  .event-info {
	font-size: 16px;
	margin: 5px 0;
	list-style: none;
	color: #6C3092;
	font-style: italic;
  }
  
.main-area {
    background-image: url(../../public/images/background.jpg);
    background-size: cover;
}

ol {
    text-align: left;
    font-size: 3vmax;
    counter-reset: li;
    list-style: none;
    padding: 0;
    margin: 2em 3em 0 3em;
    padding-bottom: 100px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

ol li {
    padding-left: 0.25em;
}

.list li{
    position: relative;
    display: block;
    padding: .4em .4em .4em .8em;
    margin: .5em 0 .5em 2.5em;
    background: #f0f4f4;
    color: black;
    text-decoration: none;
    transition: all .3s ease-out;
}

.list li:hover {
    background: #eee;
    cursor: default;
    color: #6C3092;
}

.list li:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -2.5em;
    margin-top: -.4em;
    background: #6C3092;
    color: white;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
}

.list li:after {
    position: absolute;
    content: '';
    border: .5em solid transparent;
    left: -1em;
    top: 50%;
    margin-top: -.5em;
    transition: all .3s ease-out;
}

@media screen and (min-width: 900px) {
    ol {
        font-size: 28.86px;
    }
}
.container {
    background-image: url(../../public/images/background.jpg);
    background-size: cover;
    position: initial;
}

.main {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.contact-info {
    margin: 20px 0 0 0;
    list-style: none;
    padding: 20px 0;
}

.hours-of-operation {
    font-size: 28.32px;
    list-style: none;
    padding: 20px 0;
}

.hours-of-operation-items {
    font-size: 24.27px;
}
.phone, .fax, .email, .address {
    font-size: 20.23px;
    font-weight: 600;
    padding-bottom: 5px;
}

.phone-number, .fax-number, .email-, .address- {
    font-size: 20.23px;
    display: inline;
    font-weight: 100;
}

.mapcontainer {
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
    width: 1000px;
    z-index: 0;
    align-items: center;
}

@media screen and (max-width: 809px)  {
    .hours-of-operation {
        font-size: 3.5vmax;
        list-style: none;
        padding: 20px 0;
    }
    
    .hours-of-operation-items {
        font-size: 3vmax;
    }
    .phone, .fax, .email, .address {
        font-size: 2.5vmax;
        font-weight: 600;
        padding-bottom: 5px;
    }
    
    .phone-number, .fax-number, .email-, .address- {
        font-size: 2.5vmax;
        display: inline;
        font-weight: 100;
    }
}

@media screen and (max-width: 780px) {
    MapContainer {
        margin-left: -15%;
        max-height: 3vh;
    }

    
}



:root {
    --primary: #ffffff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #6C3092;
    border: 1px solid var(--primary);
    box-shadow: 5px 5px #6C3092;

}

.btn--outline {
    background-color: #fff;
    color: #6C3092;
    padding: 8px 20px;
    border: #6C3092 1px solid;
    box-shadow: 5px 5px #6C3092;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #ffffff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--medium:active {
    background-color: #ffffff;
    box-shadow: none;
    transform: translate(5px, 5px);
    
}

.apply {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

ul {
    margin-left: 20px;
}

li {
    margin-left: 50px;
}

.link {
    text-align: center;
    font-size: larger;
}

.link a {
    color: #6C3092;
}

.link a:hover {
    color: #5a5a5c;
}

@media screen and (min-width: 1110px) {
    .main_info {
        font-size: 3vmax;
    }
    
}
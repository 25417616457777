
.btn1 {
    position: absolute;
    right: 0;
    font-size: medium;
    box-shadow: 3px 3px #000;
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #000;
    padding: 8px 20px;
    border: #000 1px solid;
}

.btn2 {
    position: absolute;
    right: 0;
    font-size: medium;
    margin-top: 37px;
    box-shadow: 3px 3px #000;
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #000;
    padding: 9px 21.5px;
    border: #000 1px solid;
    
}

